<script lang="ts" setup>
import { MenuItem } from '~/api/drupal/useMenu'
const props = defineProps<{
  items: MenuItem[] | undefined
}>()

const items =
  props.items?.map((item) => {
    return {
      id: item.id,
      title: item.title,
      items: [...(item.items || [])].map((item) => {
        return {
          id: item.id,
          link: {
            text: item.title,
            url: item.url,
            target: item.target,
          },
        }
      }),
    }
  }) || []
</script>
<template>
  <ul class="cul">
    <li v-for="item in items" :key="item.id" class="sub-menu">
      <h4>{{ item.title }}</h4>
      <LinkList :items="item.items" :animate="false" />
    </li>
  </ul>
</template>
<style lang="scss" scoped>
.sub-menu:not(:last-child) {
  margin-bottom: 50px;
}
</style>
